import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import Intercom from '@intercom/messenger-js-sdk';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import type { Address } from '@pineapple/address-search';
import { useAppSelector, useAppDispatch } from './app/hooks';
import Form from './components/Form';
import Nav from './components/Nav';
import { GetValidCoupon, CouponType } from './features/coupon/couponAPI';
import {
  setFormattedAddressFromUrl,
  setCoupon,
} from './features/signup/signupSlice';
import {
  selectAddress,
  setAddressFromUrl,
} from './features/address/addressSlice';
import {
  setDiscount,
  newCampaignDiscount,
  discountFromCoupon,
} from './features/discount/discountSlice';
import {
  twoMonthsMoveInCampaign,
  moveInCampaign,
  setMoveInCampaign,
  setCampaign,
  fetchReferralCampaign,
  Campaign,
} from './features/campaign/campaignSlice';
import { FormValues } from './lib/errors';
import { isMoverCoupon } from './lib/movers';

export const PNWebsiteBaseURL = process.env.REACT_APP_ENV === 'production'
  ? 'https://pineapple.net.au' : 'http://new.master.pineapple.net.au';

const App = () => {
  Intercom({
    app_id: 'rvp4eufp',
  });

  const address = useAppSelector(selectAddress);

  // @ts-ignore
  window.Chargebee.init({
    site: process.env.REACT_APP_ENV === 'production'
      ? 'pineapple' : 'pineapple-test',
    publishableKey: process.env.REACT_APP_ENV === 'production'
      ? 'live_G23tMxUiAVBKNyBpF4UcdNQkJYJBjNvN'
      : 'test_MBszcxZ7HRb3cdo8FAHDdLXn49fi2eRem',
  });

  const {
    register, handleSubmit, formState, getValues,
  } = useForm<FormValues>({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
  });

  const addrForm = useForm<FormValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const parsed = queryString.parse(window.location.search);
  let { uniqueCode } = parsed;
  if (uniqueCode === null || uniqueCode === undefined) {
    uniqueCode = '';
  }
  const { moveIn, referralCode, pc } = parsed;

  const dispatch = useAppDispatch();

  const handleReferralCode = async (code: string) => {
    const data = await dispatch(fetchReferralCampaign(code));
    const payload = data.payload as Campaign;
    if (data.meta.requestStatus === "fulfilled") {
      const coupon = {
        coupon_id: referralCode,
        coupon_type: payload.id === "SALES_REFERRAL"
          ? CouponType.SalesCommission : CouponType.ReferralCode,
        period: 0,
        period_unit: "month",
        starting_period: 7,
        dollar_amount: 0,
        percentage: "",
      };
      dispatch(setCoupon(coupon));
      dispatch(setDiscount(newCampaignDiscount("Referral Code Offer")));
    }
  };

  const handlePromoCode = async (code: string, planId: string) => {
    const coupon = await GetValidCoupon(
      code,
      {} as Address,
      planId,
    );

    const startingPeriod = { starting_period: 0 };
    const terms = { terms: '' };
    const inWords: { in_words?: string } = { in_words: undefined };

    if (coupon === null) return;

    if (coupon.coupon_type !== CouponType.CouponCode) return;

    if (coupon.coupon_id === "PROMOMIOMF") {
      startingPeriod.starting_period = 7;
      terms.terms = moveInCampaign.terms;
      dispatch(setCampaign(moveInCampaign));
      coupon.coupon_type = CouponType.MoveInCoupon;
    }

    if (coupon.coupon_id === "WWOMF") {
      startingPeriod.starting_period = 7;
      terms.terms = moveInCampaign.terms;
      dispatch(setCampaign(moveInCampaign));
      coupon.coupon_type = CouponType.MoveInCoupon;
    }

    if (coupon.coupon_id.includes("OMF")) {
      startingPeriod.starting_period = 7;
      terms.terms = moveInCampaign.terms;
      coupon.coupon_type = CouponType.MoveInCoupon;
    }

    if (isMoverCoupon(coupon.coupon_id)) {
      startingPeriod.starting_period = 7;
      terms.terms = twoMonthsMoveInCampaign.terms;
      dispatch(setCampaign(twoMonthsMoveInCampaign));
      coupon.period = 2;
      coupon.coupon_type = CouponType.MoveInCoupon;
    }

    dispatch(setCoupon(coupon));
    dispatch(setDiscount({
      ...discountFromCoupon(coupon),
      ...startingPeriod,
      ...terms,
      ...inWords,
    }));
  };

  useEffect(() => {
    if (pc) {
      handlePromoCode(pc as string, 'pa-150');
    }
  }, [address]);

  useEffect(() => {
    if (moveIn) {
      dispatch(setMoveInCampaign());
      const coupon = {
        coupon_id: 'PROMOMIOMF',
        coupon_type: CouponType.CouponCode,
        period: 0,
        period_unit: "month",
        starting_period: 7,
        dollar_amount: 0,
        percentage: "",
      };
      dispatch(setCoupon(coupon));
      dispatch(setDiscount(newCampaignDiscount("Move In Offer")));
    }
    if (referralCode) {
      handleReferralCode(referralCode as string);
    }
    // @ts-ignore
    if (uniqueCode !== ''
      && parsed.formatted_address
      && parsed.street_number && parsed.postcode
      && parsed.suburb && parsed.street
      && parsed.status && parsed.state
      && parsed.buildingClass && parsed.buildingCategory) {
      dispatch(setFormattedAddressFromUrl({
        // @ts-ignore
        formatted_address: parsed.formatted_address,
        // @ts-ignore
        street: parsed.street,
        // @ts-ignore
        street_number: parsed.street_number,
        // @ts-ignore
        state: parsed.state,
        // @ts-ignore
        suburb: parsed.suburb,
        // @ts-ignore
        postcode: parsed.postcode,
        // @ts-ignore
        uniqueCode: parsed.uniqueCode,
        // @ts-ignore
        status: parsed.status,
        // @ts-ignore
        mdu: parsed?.buildingClass?.toLowerCase().includes('mdu'),
        // @ts-ignore
        commercial: parsed?.buildingType?.toLowerCase().includes('commercial'),
        // @ts-ignore
        mixed: parsed?.buildingTypeExtra?.toLowerCase().includes('mixed'),
      }));
      // @ts-ignore
      dispatch(setAddressFromUrl({
        // @ts-ignore
        formatted_address: parsed.formatted_address,
        // @ts-ignore
        street: parsed.street,
        // @ts-ignore
        street_number: parsed.street_number,
        // @ts-ignore
        state: parsed.state,
        // @ts-ignore
        suburb: parsed.suburb,
        // @ts-ignore
        postcode: parsed.postcode,
        // @ts-ignore
        uniqueCode: parsed.uniqueCode,
        // @ts-ignore
        status: parsed.status,
        // @ts-ignore
        commercial: parsed?.buildingType?.toLowerCase().includes('commercial'),
        // @ts-ignore
        buildingCategory: parsed.buildingCategory,
        // @ts-ignore
        mixed: parsed?.buildingTypeExtra?.toLowerCase().includes('mixed'),
      }));
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="viewport"
          content={
          `width=device-width,
          minimum-scale=1.0,
          maximum-scale = 1.0,
          user-scalable = no`
          }
        />
      </Helmet>
      <Nav />
      <Form
        // @ts-ignore
        uniqueCode={uniqueCode}
        register={register}
        handleSubmit={handleSubmit}
        formState={formState}
        getValues={getValues}
        addrForm={addrForm}
      />
    </HelmetProvider>
  );
};

export default App;
