import React, { useState } from 'react';
import {
  Card, Button, OverlayTrigger, Popover, Form,
} from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import routerImg from '../images/xiaomi_ax3200.png';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectSignUp, setRouter } from '../features/signup/signupSlice';
import { Router } from '../models/routers';
import ScrollStack from './ScrollStack';

interface RouterCompatibilityPopoverProps {
  setShowRouterWarning: (arg: boolean) => void
}

const RouterCompatibilityPopover: React.FC<RouterCompatibilityPopoverProps> = ({ setShowRouterWarning }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backdropFilter: 'blur(10px)', // Apply blur to everything behind
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // Optional semi-transparent dark overlay
          zIndex: 1049, // One level below the popover
        }}
      />

      <Popover
        style={{
          position: 'fixed', // Fixed relative to the viewport
          top: '50%', // Center vertically
          left: '50%', // Center horizontally
          transform: 'translate(-50%, -50%)', // Adjust for its own size
          zIndex: 1050, // Ensure it appears above other elements
          maxWidth: '90%', // Adapt for smaller screens (mobile-friendly)
          width: '400px', // Default width for desktop
          borderTopLeftRadius: '3em',
          borderTopRightRadius: '3em',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(10px)', // Apply background blur
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust background for transparency
        }}
      >
        <Popover.Header
          className="text-white"
          style={{
            marginTop: "-10px",
            backgroundColor: '#f58238', // Change header color
            color: '#ffffff', // Ensure text is visible
            fontSize: "24px",
            height: "80px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          as="h1"
        >
          <br />
          Router compatibility
        </Popover.Header>
        <Popover.Body>
          <h6 className="mb-1">Connection port</h6>
          <p className="fw-light" style={{ fontSize: '0.9em' }}>
            Your router requires an
            {' '}
            <strong>Ethernet WAN/Internet Port</strong>
            .
          </p>
          <p className="fw-light" style={{ fontSize: '0.9em' }}>
            Some routers will have a WAN port
            {' '}
            <em>and</em>
            {' '}
            a DSL port - we cannot guarantee compatibility of these routers and do not recommend using them on our network.
          </p>
          <h6 className="mb-1">Brand</h6>
          <p className="fw-light" style={{ fontSize: '0.9em' }}>
            Telstra or Belong branded routers are unfortunately not compatible with our network.
          </p>
          <hr className="my-2" />
          <p className="mb-0 fw-light" style={{ fontSize: '0.9em' }}>
            Read more:
            {' '}
            <a target="_blank" href="https://kb.pineapple.net.au/help/is-my-router-compatible" rel="noreferrer">Is my router compatible?</a>
          </p>
          <hr className="my-2" />
          <Form.Group className="mb-2" controlId="compatibilityCheck" style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Form.Check
              type="checkbox"
              onChange={handleCheckboxChange}
              style={{
                accentColor: '#f58238', // Change checkbox color
                paddingTop: "5px",
                height: '0.5em', // Adjust checkbox height
              }}
              checked={isChecked}
            />
            <div style={{ marginLeft: "50px" }}>
              I acknowledge the router compatibility requirements
            </div>
          </Form.Group>
          <Button
            style={{
              backgroundColor: '#f58238', // Change button color
              borderColor: '#f58238', // Match border color
            }}
            size="lg"
            className="mt-3"
            id="confirm-byo-router"
            disabled={!isChecked}
            onClick={() => setShowRouterWarning(false)}
          >
            Confirm
          </Button>
        </Popover.Body>
      </Popover>
    </>

  );
};

interface RoutersProps {
  routers: Record<string, Router>;
  className?: string,
}

const Routers = ({ className = '', routers }: RoutersProps) => {
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector(selectSignUp);
  const [showRouterWarning, setShowRouterWarning] = useState(false);

  const routersState: Record<string, boolean> = Object.keys(routers)
    .reduce((acc, id) => ({
      ...acc,
      [id]: signUpState.router.id === id,
    }), {});

  const [
    buttonState,
    setButtonState,
  ] = useState<Record<string, boolean>>(routersState);
  const setChange = (router: string) => {
    Object.keys(buttonState).forEach((routerName) => {
      buttonState[routerName] = false;
    });
    buttonState[router] = !buttonState[router];
    setButtonState({
      ...buttonState,
    });
    dispatch(setRouter(routers[router]));
  };

  return (

    <>
      { showRouterWarning
          && (
            <RouterCompatibilityPopover setShowRouterWarning={setShowRouterWarning} />
          )}
      <ScrollStack
        mostPopular={false}
        className={`
          hide-scrollbar
          p-2
          overflow-scroll
          d-flex
          align-items-stretch ${className}`}
        gap={2}
        direction="horizontal"
      >
        {Object.keys(routers).map((id) => (
          <Card
            key={id}
            className="shadow pineapple-card"
            border="gray"
          >

            <Card.Body className="d-flex flex-column">
              <Card.Title
                className="
                  text-center
                  text-primary
                  border-bottom
                  border-primary
                  fs-3
                  pb-2
                  mb-0"
              >
                {routers[id].name}
              </Card.Title>
              <Card.Text className="py-2">
                { routers[id].description1
                  ? `${routers[id].description1}\n` : null }
              </Card.Text>
              { routers[id]?.cost !== '0'
                ? (
                  <div
                    className="d-flex justify-content-center"
                  >
                    <div className="position-relative d-inline-block">
                      <Card.Img
                        style={{ width: 'auto', height: '200px' }}
                        src={routerImg}
                      />
                    </div>
                  </div>
                ) : null }
              <div className="w-100 align-self-end mt-auto">
                <Card.Text className="text-center">
                  <span className="
                  display-1
                  text-primary
                  text-center
                  lh-1
                  d-block"
                  >
                    $
                    {routers[id]?.cost || 0}
                  </span>
                  <span className="fs-6">
                    {routers[id]?.description2}
                  </span>
                </Card.Text>
                <Button
                  className="w-100"
                  size="lg"
                  onClick={() => {
                    if (routers[id]?.cost === '0') setShowRouterWarning(true);
                    if (routers[id]?.cost !== '0') setShowRouterWarning(false);
                    setChange(id);
                  }}
                  variant={buttonState[id] ? 'secondary' : 'primary'}
                  id={`select_router_${id}`}
                >
                  { buttonState[id] ? 'Selected' : 'Select' }
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </ScrollStack>
    </>
  );
};

export default Routers;
